<template>
  <div class="e-right-navbar">
    <div class="e-nav-menus">
      <b-nav
          vertical class="e-nav-menu w-25"
          v-for="(item, i) in $store.state.verticalMenu.rightNavMenu"
          :key="i"
      >
        <b-nav-item @click=goSection(item) >
          {{ item }}
        </b-nav-item>
      </b-nav>
    </div>
  </div>
</template>

<script>

export default {
  name: "RightSidebar",
  methods: {
    goSection(hash) {
      location.hash = hash
    }
  }
}

</script>